import React, { useState, useRef } from "react";
import '../components/home.css';

export default function Expandable({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null); // reference to the content div

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={styles.container} className="expandable">
      <div onClick={toggleOpen} style={styles.header}>
        <h3 className="question">{title}</h3>
        <button style={styles.button}>{isOpen ? "-" : "+"}</button>
      </div>
      <div
        ref={contentRef}
        style={{
          ...styles.content,
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0",
          transition: "max-height 0.3s ease",
        }}
      >
        {children}
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: "#F3EEE9",
    borderRadius: "4px",
    marginBottom: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    padding: "15px 10px",
    backgroundColor: "#F3EEE9",
  },

 
  button: {
    background: "none",
    border: "none",
    fontSize: "18px",
    cursor: "pointer",
  },
  content: {
    overflow: "hidden", // Ensures smooth transition by hiding content during collapse\
    backgroundColor: "#F3EEE9",
    padding: "2px 5px",
    fontSize: "12px",
    maxHeight: "0", // Initially set maxHeight to 0 for closed state
  },
};

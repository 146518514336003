import React from "react";
import "./footer.css"; // Ensure you create this file for styling
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer">
        <div className="map-container">
          <iframe
            width="50vh"
            height="100%"
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3423.165862809851!2d77.10206187538037!3d30.9099876744992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f814a91d0b091%3A0x218c801a4501d60f!2sSahni%20Complex%2C%20Upper%20Bazar%2C%20Bajoral%20Khurd%2C%20Solan%2C%20Himachal%20Pradesh%20173212!5e0!3m2!1sen!2sin!4v1728019523636!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="footer-content-container">
          <div className="footer-logo"></div>
          <div className="link-container">
            <div className="container1">
              <Link className="link" to="">
                Home
              </Link>
              <Link className="link" to="">
                About
              </Link>
              <Link className="link" to="">
                Contact us
              </Link>
            </div>

            <div className="container2">
              <Link className="link" to="">
                Refund policy
              </Link>
              <Link className="link" to="">
                Privacy policy
              </Link>
              <Link className="link" to="">
                Terms & conditions
              </Link>
            </div>

            <div className="container3">
              <Link className="link" to="">
                Blogs
              </Link>
              <Link className="link" to="">
                Events
              </Link>
              <Link className="link" to="">
                Courses us
              </Link>
            </div>
          </div>
          <div className="social-container">
            <h1>Follow us on:</h1>
            <div className="social-icons-container">
              <Link className="footer-link" to="facebook.com/youyatra11">
                <div className="facebook"></div>
              </Link>
              <Link
                className="footer-link"
                to="https://www.instagram.com/yogyatrapath/"
              >
                <div className="instagram"></div>
              </Link>
              <Link className="footer-link" to="www.youtube.com/@Yogyatra11">
                <div className="youtube"></div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-foot">
        <span> Copyright. all rights reserved 2024</span>
        <Link className="link" to="https://www.dovetailsolutions.in/">
          Powered by Dovetail solutions
        </Link>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoLocation, IoMail } from "react-icons/io5";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const address =
    "1st floor sahni complex near hari mandir rajgarh, road, solan (173212)";
  let addressmain = address.toUpperCase();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="w-full bg-gray-200 py-2 px-2 flex max-sm:flex-col">
        <div className="w-full h-auto flex gap-1 justify-start items-center text-xs p-0 m-0 max-sm:justify-center max-sm:hidden">
          <span className="p-0 m-0">
            <IoLocation />
          </span>
          <p className="text-xs p-0 m-0">{addressmain}</p>
        </div>
        <div className="w-full h-full flex justify-end items-center p-0 m-0 gap-2 max-sm:justify-center">
          <div className="flex gap-1 p0 m-0">
            <span className="p-0 m-0 text-xs">
              <FaPhoneAlt />
            </span>{" "}
            <p className="p-0 m-0 text-xs">+91-9817935353</p>
          </div>
          <div className="flex gap-1 p0 m-0">
            <span className="p-0 m-0 text-xs">
              <IoMail />
            </span>{" "}
            <p className="p-0 m-0 text-xs">info@yogyatra.co.in</p>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo - Left side */}
          <div className="flex-shrink-0">
            <Link to="/" className="text-xl font-bold text-gray-800">
              <div className="logo w-32 h-10 bg-center bg-contain bg-no-repeat"></div>
            </Link>
          </div>

          {/* Navigation Links - Right side */}
          <div className="hidden md:flex space-x-8 ml-auto">
            {" "}
            {/* ml-auto pushes items to the right */}
            <Link
              to="/"
              className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-semibold"
            >
              Home
            </Link>
            {/* <Link
            to="/courses"
            className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-semibold"
          >
            Courses
          </Link> */}
            <Link
              to="/about"
              className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-semibold"
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className="text-gray-600 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-semibold"
            >
              Contact
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={toggleMenu}
              className="bg-gray-200 inline-flex items-center justify-center p-2 rounded-md text-gray-800 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu with Animation */}
      <div
        className={`md:hidden transition-all duration-300 ease-in-out ${
          isOpen
            ? "max-h-screen opacity-100"
            : "max-h-0 opacity-0 overflow-hidden"
        }`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Link
            to="/"
            className="text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
          >
            Home
          </Link>
          {/* <Link
          to="/courses"
          className="text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
        >
          Courses
        </Link> */}
          <Link
            to="/about"
            className="text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="text-gray-600 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium"
          >
            Contact
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

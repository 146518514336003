import React from "react";
import { useForm } from "react-hook-form";
import "./contact.css"; // Custom styles if needed
import Navbar from "./navbar";
import Footer from "./footer";
import { motion } from "framer-motion";
import { IoSend } from "react-icons/io5";
import { BiMailSend, BiPhone } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function ContactForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    alert("Form submitted successfully!");
  };

  return (
    <>
      <Navbar />
      <div className="contact-main-container">
        <div className="contact-container max-sm:w-full max-sm:px-1">
          <div className="contact-sub-container max-sm:flex-col">
            <div className="contact-form-sidepanel">
              <div className="talk-first-container">
                <h1
                  style={{ padding: "0", fontSize: "40px", fontWeight: "600" }}
                >
                  Let's talk
                </h1>
                <h2>Start your yoga journey today!</h2>
              </div>
              <div className="talk-second-container">
                <div className="basicontact">
                  <BiPhone className="contact-talk" />
                  <h3>+91 980 (5666) 177</h3>
                </div>

                <div className="basicontact">
                  <BiMailSend className="contact-talk" />
                  <h3>info@yogayatra.co.in</h3>
                </div>
              </div>
            </div>
            <div className="contact-form-container">
              <h2
                style={{
                  padding: "10px 0",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                Contact Us
              </h2>
              <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                <div className="flex justify-center items-center w-full flex-1">
                  <div
                    style={{ width: "100%", padding: "5px 5px" }}
                    className="px-1"
                  >
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      className="contact-input"
                      {...register("name", { required: true })}
                    />
                    {errors.name && <p className="error">Name is required</p>}
                  </div>

                  <div
                    style={{ width: "100%", padding: "5px 5px" }}
                    className="px-1"
                  >
                    <label htmlFor="email">Email</label>
                    <input
                      id="email"
                      className="contact-input"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                    />
                    {errors.email && (
                      <p className="error">Please enter a valid email</p>
                    )}
                  </div>
                </div>
                {/* Name Field */}

                <div>
                  <label htmlFor="comment">Comment</label>
                  <input
                    className="contact-input"
                    id="comment"
                    {...register("comment", { required: true })}
                  />
                  {errors.comment && (
                    <p className="error">Comment is required</p>
                  )}
                </div>

                <div className="flex flex-col gap-3">
                  <label htmlFor="course">Course</label>
                  <select
                  defaultValue="Select course"
                    className="contact-input text-white w-full py-2 px-2 rounded-md" style={{backgroundColor: "#f5928b"}}
                    id="comment"
                    {...register("course", { required: true })}
                  >
                    <option style={{color: "#fff"}} value="selectcourse">Select course</option>
                    <option style={{color: "#fff"}} value="certificate">Certificate in yoga</option>
                    <option style={{color: "#fff"}} value="advancecertificate">Advance certificate in yoga</option>
                    <option style={{color: "#fff"}} value="diploma">Diploma in yoga</option>
                  </select>
                  {errors.comment && (
                    <p className="error">Comment is required</p>
                  )}
                </div>
                {/* Submit Button */}
                <button type="submit" className="submit-button">
                  Submit
                  <IoSend />
                </button>
              </form>
            </div>
          </div>
          <div className="social-link-container">
            <Link to="facebook.com/youyatra11">
              <FaFacebook className="contact-talk" />
            </Link>
            <Link to="https://www.instagram.com/yogyatrapath/">
              <FaInstagram className="contact-talk" />
            </Link>
            <Link to="www.youtube.com/@Yogyatra11">
              <FaYoutube className="contact-talk" />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

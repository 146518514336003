import React from "react";
import "./AboutUsPage.css";
import "../index.css";
import Navbar from "./navbar";
import Footer from "./footer";
import "../index.css";
import Section from "../Modules/Section";
import Row from "../Modules/Row";

const AboutUsPage = () => {
  return (
    <div className="about-us">
      <Navbar />
      <section className="w-full flex border-r-0 justify-center items-center flex-col head bg-center bg-no-repeat bg-cover">
      </section>

      <Section classname="background rounded-md">
        <div classname=" w-full flex justify-center items-center flex-col py-6">
          <h1 className="text-5xl font-semibold text-center">
            Welcome to yog yatra
          </h1>
          <div className="flex justify-center items-center flex-col gap-4 border border-gray-50 shadow-md text-center my-7 bg-white">
            <p className=" py-10 px-6 rounded-xl shadow-sm shadow-gray-100">
              At Yog yatra, we believe yoga is more than just a physical
              practice it's a path to balance, strength, and inner peace. With a
              passion for well-being, we offer carefully designed courses for
              all levels, making yoga accessible to everyone. Whether you're a
              beginner or experienced, our mission is to guide you through a
              journey of self-discovery and mindful movement. Join us as we
              create a welcoming space where you can connect with your body,
              mind, and soul, and elevate your life through yoga.
            </p>
          </div>
        </div>

        <Row classname=" w-full h-full pt-10 pb-5 max-sm:pb-3">
          <div className="w-full h-full flex flex-col justify-center items-start max-sm:items-center backgroundyogabase">
            <h2 className="heading font-semibold">What we do?</h2>
            <p className="text-lg max-sm:pt-2 max-sm:text-center">
              We offer expertly designed yoga courses that help you achieve
              physical, mental, and spiritual well-being. Our mission is to
              raise awareness about the transformative power of yoga and its
              numerous benefits. Whether you're a beginner or an advanced
              practitioner, our courses guide you towards a balanced, healthy
              lifestyle through mindful movement and meditation."
            </p>
          </div>
          <div className=" w-full h-full backgroundyoga1 flex backgroundyogabase"></div>
        </Row>

        <Row classname=" w-full h-full py-5 flex-row-reverse">
          <div className="w-full h-full flex flex-col justify-center items-start max-sm:items-center backgroundyogabase">
            <h2 className="heading font-semibold">Our vision</h2>
            <p className="text-lg text-start max-sm:pt-2 max-sm:text-center">
              Our vision is to create a global community that embraces the
              holistic practice of yoga, fostering physical, mental, and
              emotional well-being. We strive to inspire individuals to lead
              healthier, more balanced lives by making yoga accessible and
              relatable to all. Through education and practice, we aim to
              empower people to live with mindfulness, peace, and purpose.
            </p>
          </div>
          <div className=" w-full h-full backgroundyoga2 flex backgroundyogabase"></div>
        </Row>

        <Section classname="">
          <h1 className="text-4xl font-semibold">How we work</h1>
          <Row classname="w-full h-full pt-7 pb-2 gap-4 px-5 max-sm:px-1">
            <div className="flex flex-col justify-center items-center px-3 py-6 shadow-md bg-white shadow-gray-100 rounded-lg border border-gray-50">
              <div className="learning bg-center bg-contain bg-no-repeat h-24 w-24 px-3 py-2"></div>
              <h2 className="heading text-2xl pb-0 font-semibold">
                Personalized Learning
              </h2>
              <p className="text-center text-lg">
                Our personalized yoga courses are tailored to individual needs,
                ensuring a unique, guided journey towards improved well-being
                and growth.
              </p>
            </div>
            <div className="flex flex-col gap-2 justify-center items-center px-3 py-6 shadow-md shadow-gray-100 bg-white rounded-lg border border-gray-50">
              <div className="courses bg-center bg-contain bg-no-repeat h-24 w-24"></div>
              <h2 className="heading text-2xl pb-0 font-semibold">
                All level Courses
              </h2>
              <p className="text-center text-lg">
                Our courses cater to all levels, from beginners to advanced
                practitioners, offering step-by-step guidance to enhance your
                yoga practice.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center px-3 py-6 shadow-md shadow-gray-100 bg-white rounded-lg border border-gray-50">
              <div className="trainers bg-center bg-contain bg-no-repeat h-24 w-24 px-3 py-2"></div>
              <h2 className="heading text-2xl pb-0 font-semibold">
                Expert trainers
              </h2>
              <p className="text-center text-lg">
                Our experienced trainers bring years of expertise, guiding you
                with personalized attention, ensuring safe, effective, and
                transformative yoga practices.
              </p>
            </div>
          </Row>
        </Section>
      </Section>
      <Footer />
    </div>
  );
};

export default AboutUsPage;


import React from "react";
import people from "../assets/home/Frame.png";
import video from "../assets/home/Frame (1).png";
import om from "../assets/home/Clip path group.png";


export const Featuredata = [
    {
      imgSrc: people,
      alt: "Yogastile",
      title: "Health mastery",
      description: "Achieve physical health and flexibility through expert-guided yoga routines."
    },
    {
      imgSrc: video,
      alt: "Videos",
      title: "All-level yoga courses",
      description: "Comprehensive online yoga courses for all levels of practice."
    },
    {
      imgSrc: om,
      alt: "Testen",
      title: "Mindful spiritual growth",
      description: "Cultivate inner peace, balance, and spiritual growth through mindful yoga."
    }
  ];

  
  
import { section } from "framer-motion/client";
import React from "react";
import "../index.css";

function Section({ children, classname, justifycontent = "center", alignitems = "center" }) {
  return (
    <section
      className={`w-full h-full flex py-16 px-10 flex-col bg-no-repeat bg-contain bg-center max-sm:px-2 max-sm:py-7 ${classname}`}
      style={{justifyContent: `${justifycontent}`, alignItems: `${alignitems}`}}
    >
      {children}
    </section>
  );
}

export default Section;

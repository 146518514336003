import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutUsPage from "./components/About";
import ContactForm from "./components/Contact";
import Blog from "./components/Blog";
import Cancellation from "./components/Cancellation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/about",
    element: <AboutUsPage />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "/cancellationpolicy",
    element: <Cancellation />,
  },
  {},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

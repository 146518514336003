import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Section from "../Modules/Section";

function Cancellation() {
  return (
    <>
      <Navbar />
      <Section>Cancellation policy</Section>
      <Footer />
    </>
  );
}

export default Cancellation;

import React from "react";
import Navbar from "./navbar";
import Section from "../Modules/Section";
import Footer from "./footer";
import "./blog.css";
import yoga1 from "../assets/home/blog page yog yatra.jpg";
import yoga2 from "../assets/home/yogapose.jpg";
import yoga3 from "../assets/home/yogapose1.png";
import yoga4 from "../assets/home/slider3.png";
import { Link } from "react-router-dom";

const yogaBlogs = [
  {
    title: "How Yoga Transforms Both Body and Mind",
    description: "Explore the physical, mental, and emotional benefits of yoga, and how it can transform your entire life. This blog delves into how yoga enhances flexibility, strength, mental clarity, and stress relief.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "October 4, 2024",
    image: yoga1,
    link: "https://yogyatra.com/blogs/yoga-transforms-body-mind"
  },
  {
    title: "The Top 5 Yoga Poses for Stress Relief and Relaxation",
    description: "Discover the best yoga poses for stress relief. Whether you're a beginner or experienced yogi, these poses will help you relax and find balance in your life.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "October 1, 2024",
    image: yoga2,
    link: "https://yogyatra.com/blogs/top-5-yoga-poses-stress-relief"
  },
  {
    title: "Yoga for Beginners: How to Get Started on Your Journey",
    description: "If you're new to yoga, this blog will guide you through the basics, including tips on choosing the right style, what to expect in your first class, and essential equipment for your practice.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "September 28, 2024",
    image: yoga3,
    link: "https://yogyatra.com/blogs/yoga-for-beginners"
  },
  {
    title: "Mastering Anulom Vilom Pranayama for Inner Peace",
    description: "Learn the step-by-step process of practicing Anulom Vilom Pranayama, and how this ancient breathing technique can bring mental clarity and inner peace.",
    writtenBy: "Yog Yatra Team",
    writtenOn: "September 20, 2024",
    image: yoga4,
    link: "https://yogyatra.com/blogs/master-anulom-vilom-pranayama"
  }
];


function Blog() {
  return (
    <>
      <Navbar />
      <Section classname="w-full flex bloghero flex-col justify-center items-center bg-cover bg-no-repeat">
        <h1>Blogs</h1>
      </Section>
      <Section>
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 w-full">
            <div className="mx-auto max-w-4xl lg:text-center pb-12 pt-4">
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Welcome to the Yog Yatra Blog – your go-to resource for
                insightful articles on yoga, wellness, and holistic living.
                Whether you're just starting your yoga journey or looking to
                deepen your practice, explore our curated content to inspire,
                educate, and guide you every step of the way.{" "}
              </p>
            </div>
            <div className=" w-full h-full justify-between items-center grid grid-cols-2 gap-3">
              {yogaBlogs.map((element) => (
                <div
                  style={{
                    backgroundImage: `linear-gradient(#fff, #fff),url(${element.image})`,
                  }}
                  className="flex justify-end items-center flex-col h-auto w-full bg-center bg-cover bg-no-repeat rounded-lg gap-2 border shadow-md shadow-gray-100"
                >
                  <div className="py-10 gap-4 flex flex-col px-6 bg-white">
                    <h1 className=" text-2xl font-semibold h-14">
                      {element.title}
                    </h1>
                    <img src={element.image} alt="" className="h-48 w-full object-cover"/>
                    <p>{element.description}</p>
                    <Link
                      className="w-1/4 flex-row py-2 px-2 border rounded-md readmore flex justify-center items-center"
                      to={element.link}
                    >
                      Read more...
                    </Link>
                  </div>
                  <div className="w-full flex gap-2 bg-white items-center py-1 px-2">
                    <p className="text-sm">Written by: {element.writtenBy}</p>
                    <p className="text-sm">on {element.writtenOn}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}

export default Blog;

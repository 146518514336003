import React from 'react';
import Navbar from './components/navbar'; 
import Home from './components/home';
import Footer from './components/footer';
import './App.css';
import { createBrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <Footer />
    </>
  );
}

export default App;

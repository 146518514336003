import { style } from "framer-motion/client";
import React from "react";

function Row({ children, classname, bgcolor }) {
  return (
    <div style={{backgroundColor : `${bgcolor}`}}
      className={`${classname} flex flex-row justify-center item-center max-sm:flex-col` }
    >
      {children}
    </div>
  );
}

export default Row;

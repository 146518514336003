import video1 from "../assets/home/div.png";
import video2 from "../assets/home/big_webp_moritz-ulrich-daily-practice-jivamukti-morgen-02-still.jpg.webp.png";
import video3 from "../assets/home/big_webp_nina-heitmann-natarajasana-flow-still.jpg.webp.png";


export const Plans = [
    {
      img: video1,
      alt: "Yog Yatra",
      title: "Certificate in yoga",
      description: "Beginner’s journey to inner peace.",
      duration: "3-Month Plan",
      price: "₹10,000",
      planType: "certificate in yoga",
    },
    {
      img: video2,
      alt: "Yog Yatra path of peace",
      title: "Advance certificate in yoga",
      description: "Deepen your practice and flexibility.",
      duration: "6-Month Plan",
      price: "₹15,000",
      planType: "Advance certificate in yoga",
    },
    {
      img: video3,
      alt: "Yog Yatra path of peace",
      title: "Diploma in yoga",
      description: "Transform your mind, body, and soul.",
      duration: "1-Year Plan",
      price: "₹35,000",
      planType: "diploma in yoga",
    }
  ];
  